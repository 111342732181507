
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import UPPHistory from "@/components/upp/UPPHistory.vue";
// import BalanceDetails from "@/components/cards/BalanceDetails.vue";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import ECShopAdvisory from "@/views/ECShopAdvisory.vue";


export default defineComponent({
  name: "upp",
  components: {
    UPPHistory,
    // BalanceDetails,
    // CompanyPolicy,
    // ECShopAdvisory
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("uppTab") || "0");
      setCurrentPageTitle("UniPay Payout Plan (UPP)");

    });
    
  }
});
